import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Tenant } from '../../model/Tenant';
import { Subject } from '../../model/Subject';
import { Document } from '../../model/Document';
import { IngestedDocument } from '../../model/IngestedDocument';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit{

  selectedTenantId:String|null = null;
  tenant:Tenant|null = null
  subjects:Subject[] = [];
  selectedSubjectId:String|null = null;
  selectedSubjectPrompt:string = '';
  documents!:Document[];
  selectedDocument:Document|null = null;
  selectedDocumentId: string | null = null;
  selectedFile: File | null = null;
  submitNew=false;
  digestedDocument: IngestedDocument | null = null;
  loading = false;
  seePrompt = false;
  tenants: Tenant[] = [];
  removedoc:boolean = false
  adddoc:boolean = false
  newDocumentName:string|null= null

  constructor(private _mainService: MainService, private router: Router){
  }

  ngOnInit(): void {
    this.getTenants()    
  }

  getTenants(){
    this._mainService.getTenants().subscribe((tenants) => {
      if(tenants){
        this.tenants = tenants
      } else {
        console.warn("No tenants")
      }
    }) 
  }

  goToTenantChat(){
    if(this.tenant){
      this.router.navigate(['/chat/' + this.tenant.tenant_id]);
    }
  }

  onTenantChange(event: Event){
    const selectedIndex = (event.target as HTMLSelectElement).selectedIndex;
    this.tenant = this.tenants[selectedIndex]
    this.getSubjects()
  }
  

  getSubjects():void {
    this.selectedSubjectId = null;
    if(this.tenant && this.tenant.tenant_id){
      this._mainService.getSubjects(this.tenant.tenant_id).subscribe((subjects) => {
        if(subjects){
          this.subjects = subjects; 
          this.getDocuments();         
        } else {
          console.warn("No subjects for giving tenant_id ", this.tenant?.tenant_id)
        }
      })
    }
  }

  onSubjectChange(event: Event) {
    const selectedIndex = (event.target as HTMLSelectElement).selectedIndex;
    this.selectedSubjectId = this.subjects[selectedIndex].subject_id;
    this.selectedSubjectPrompt = this.subjects[selectedIndex].prompt;
    this.getDocuments();
  }

  goToSoggettoChat(){
  if(this.tenant && this.selectedSubjectId){
      this.router.navigate(['/chat/' + this.tenant.tenant_id + '/' + this.selectedSubjectId]);
    }
  }

  getDocuments():void {
    this.selectedDocument = null;
    if(this.tenant && this.tenant.tenant_id && this.selectedSubjectId){
      this._mainService.getDocuments(this.tenant.tenant_id, this.selectedSubjectId).subscribe((documents) => {
        if(documents){
          this.documents = documents;
        } else {
          console.warn("No documents for giving tenant_id and subject_id", this.tenant?.tenant_id, this.selectedSubjectId)
        }
      })
    }
  }

  onDocumentChange(event: Event) {
    const selectedIndex = (event.target as HTMLSelectElement).selectedIndex;
    this.selectedDocument = this.documents[selectedIndex];
    this.selectedDocumentId = this.selectedDocument.document_id
  }

  

  onFileChange(event: Event) {
    this.digestedDocument = null;
    const files = (event.target as HTMLInputElement).files;
    if(files && files.length>0){
      this.selectedFile = files[0];
    }    
    this.onFileSubmit();
  }

  onFileSubmit() {
    if (this.selectedFile) {
      // Send the file using an HTTP service or other methods
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      // ... send formData using your preferred HTTP service
      this._mainService.digestDocument(this.selectedFile).subscribe((digestedDocument) => {
        this.digestedDocument = null;
        if(digestedDocument){
          this.digestedDocument = digestedDocument;
        }
      })
    }
  }

  submitDigestedDocument(){
    if(this.tenant && this.tenant.tenant_id && this.selectedSubjectId && this.digestedDocument && this.selectedDocument){
      this.loading = true;
      this._mainService.submitDigestedDocument(this.tenant.tenant_id, this.selectedSubjectId,
        this.selectedDocument.document_id, this.digestedDocument)
          .subscribe((document) => {
            this.selectedDocument = document
            this.submitNew = false
            this.digestedDocument = null
            this.loading = false;
          })
    }    
  }

  submitPrompt(){
    if(this.tenant && this.tenant.tenant_id && this.selectedSubjectId){
        const subject = this.subjects.find(subject => subject.subject_id === this.selectedSubjectId)        
        if(subject){
          subject.prompt = this.selectedSubjectPrompt
          this._mainService.submitSubject(this.tenant.tenant_id, this.selectedSubjectId, subject)
          .subscribe((subject) => {
            this.seePrompt = false
          })
        } else {
          console.warn('no subject found for giving subjectid', this.selectedSubjectId)
        }
        
    }
  }

  removeDocument(){
    if(this.tenant && this.tenant.tenant_id && this.selectedSubjectId && this.selectedDocument && this.selectedDocument.document_id){
        this._mainService.removeDocument(this.tenant.tenant_id, this.selectedSubjectId, this.selectedDocument.document_id)
        .subscribe(() => {
          this.removedoc = false
          this.getDocuments();
        })
    } else {
      console.warn("Tenant, Subject and Document are mandatory")
    }
  }

  addDocument(){
    if(this.tenant && this.tenant.tenant_id && this.selectedSubjectId && this.newDocumentName){
      this._mainService.addDocument(this.tenant.tenant_id, this.selectedSubjectId, this.newDocumentName)
      .subscribe(() => {
          this.adddoc = false
          this.getDocuments();
        })
    } else {
      console.warn("Tenant, Subject and New Document Name are mandatory")
    }
  }

}
