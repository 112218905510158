import { Component } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ActivatedRoute } from '@angular/router';
import { Tenant } from '../../model/Tenant';
import { Subject } from '../../model/Subject';
import { Question } from '../../model/Question';
import { Answer } from '../../model/Answer';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {

  tenant:Tenant|null = null;
  subjects:Subject[]|null = null;
  selectedSubject: Subject|null = null
  selectedSubjectId:string|null = null;
  questionContent:string|null = null;
  answer:Answer = new Answer();
  answering:boolean = false;
  focosOnSubject = false;
  ai_engines = [
    {
      'id':'openai',
      'name': 'Open AI'
    }
  ]
  selectedAIEngine:string|null = this.ai_engines[0]['id'];

  constructor(private _mainService: MainService, private activatedRoute: ActivatedRoute){
  }

  ngOnInit(): void {
    const tenant_id = this.activatedRoute.snapshot.params['tenant_id'];
    const subject_id = this.activatedRoute.snapshot.params['subject_id'];
    if(subject_id){
      this.selectedSubjectId = subject_id
      this.focosOnSubject = true
    } else {
      this.focosOnSubject = false
    }
    this._mainService.getModelList().subscribe((model_list) => {
      this.ai_engines = model_list
    }) 
    this._mainService.getTenant(tenant_id).subscribe((tenant) => {
      if(tenant){
        this.tenant = tenant;
        this.getSubjects()
      } else {
        console.warn("No tenant for giving tenant_id ", tenant_id)
      }
    })
  }

  getSubjects():void {
    if(!this.focosOnSubject){
      this.selectedSubjectId = null;
    }    
    if(this.tenant && this.tenant.tenant_id){
      this._mainService.getSubjects(this.tenant.tenant_id).subscribe((subjects) => {
        if(subjects){
          this.subjects = subjects;
          if(this.focosOnSubject && this.subjects){
              this.selectedSubject = this.subjects.find(subject => subject.subject_id === this.selectedSubjectId) || null;
          }        
        } else {
          console.warn("No subjects for giving tenant_id ", this.tenant?.tenant_id)
        }
      })
    }
  }

  onSubjectChange(event: Event) {
    const selectedIndex = (event.target as HTMLSelectElement).selectedIndex;
    const selectedSubject = this.subjects ? this.subjects[selectedIndex] : null;
    if(selectedSubject){
      this.selectedSubjectId = selectedSubject.subject_id;
    }      
  }

  ask(){
    this.answering = true
    this.answer = new Answer()
    if(this.tenant && this.tenant.tenant_id && this.selectedSubjectId && this.questionContent && this.selectedAIEngine){
      const question = new Question()
      question.body = this.questionContent
      this._mainService.ask(this.tenant.tenant_id, this.selectedSubjectId, question, this.selectedAIEngine).subscribe((answer) => {
        if(answer){
          this.answer = answer
        } else {
          console.warn("No answer")
        }
        this.answering = false;
      })
    }
    
  }  

}
