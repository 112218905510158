<div class="d-flex justify-content-between flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-primary text-white border-bottom box-shadow">
  <h5 class="my-0 mr-md-auto font-weight-normal">NN</h5>
  <div class="my-2 my-md-0 mr-md-3">
    User: {{user.firstName}}
  </div>
  <!--<nav class="my-2 my-md-0 mr-md-3">
    <a class="p-2 text-dark" routerLink="/">Ask</a>
    <a class="p-2 text-dark" routerLink="/admin">Digest</a>
  </nav>-->
</div>
