
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthConfig } from "angular-oauth2-oidc";
import { User } from '../model/User';

const authConfig: AuthConfig = {
  issuer: environment.authIssuer,
  redirectUri: window.location.origin + environment.authRedirectPath,
  clientId: environment.authClientId,
  scope: environment.authScope,
  responseType: 'code',
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  clearHashAfterLogin: true,
  useSilentRefresh: true
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  public user:User|null = null;

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get token(): string {
    return this.oauthService.getIdToken()
  }

  constructor( private oauthService: OAuthService ) {
    const currentUser = localStorage.getItem('currentUser')
    this.currentUserSubject = new BehaviorSubject<User>(currentUser ? JSON.parse(currentUser) : null);
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUser.subscribe(x => (this.user = x));
  }

  async initAuth(): Promise<any> {
    return new Promise<void>((resolveFn, rejectFn) => {
      // setup oauthService
      this.oauthService.configure(authConfig);
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.setupAutomaticSilentRefresh();

      // subscribe to token events
      this.oauthService.events
        .pipe(filter((e: any) => e.type === 'token_received' || e.type === 'token_refreshed'))
        .subscribe((e) => {
          this.handleNewToken();
        });

      this.oauthService.loadDiscoveryDocumentAndTryLogin().then(isLoggedIn => {
        // console.log(this.oauthService.getAccessToken())
        if (isLoggedIn && this.oauthService.getAccessToken() && this.oauthService.hasValidAccessToken()) {          
          this.oauthService.setupAutomaticSilentRefresh();
          this.fetchUser();
          this.handleNewToken();
          resolveFn();
          // if you don't use clearHashAfterLogin from angular-oauth2-oidc you can remove the #hash or route to some other URL manually:
          // const lazyPath = this.injector.get(LAZY_PATH) as string;
          // this.injector.get(Router).navigateByUrl(lazyPath + '/a') // remove login hash fragments from url
          //   .then(() => resolveFn()); // callback only once login state is resolved
        } else {
          console.log('not logged');
          localStorage.setItem("pastPath", window.location.pathname)
          this.oauthService.initLoginFlow();
          rejectFn();
        }
      });
    });
  }

  private handleNewToken() {
    if(this.user){
      this.setUser(this.user)
    }
  }

  private fetchUser(): void {
    const identityClaims = this.oauthService.getIdentityClaims();
    if(identityClaims){
      const user = new User();
      user.email = identityClaims['email'];
      user.firstName = identityClaims['name'];
      user.lastName = identityClaims['family_name'];
      user.groups = identityClaims['groups'];
      this.setUser(user)
    }
  }

  private setUser(user: User | null): void {
    if(user){
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    } else {
        localStorage.removeItem('currentUser');
    }
    
    
  }

  public logout(): void {
    this.setUser(null);
    this.oauthService.logOut();
  }

}