<div class="container pb-2 mt-4">
	<h2>
		<i class="bi bi-chat-right-text-fill"></i> {{tenant?.name}} <span *ngIf="focosOnSubject"> - {{selectedSubject?.name}}</span>
	</h2>
	<div class="card mt-4">
		<div class="card-body">
            <div class="form-group mb-2">
                <div class="form-group mb-4 mt-4" *ngIf="!focosOnSubject">
                    <label class="mb-2 fw-bold text-primary">Soggetto</label>
                    <select class="form-control" [(ngModel)]="selectedSubjectId">
                        <option *ngFor="let subject of subjects" [value]="subject.subject_id">
                            {{ subject.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group mb-4 mt-4" [hidden]="true">
                    <label class="mb-2 fw-bold text-primary">Engine</label>
                    <select class="form-control" [(ngModel)]="selectedAIEngine">
                        <option *ngFor="let ai_engine of ai_engines" [value]="ai_engine.id">
                            {{ ai_engine.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group mb-4">
				    <label class="mb-2 fw-bold text-primary">La tua domanda</label>
                    <textarea                        
                        [(ngModel)]="questionContent"
                        placeholder="es. Quali sono i fattori di rischio elevato?"
                        class="form-control"
                        rows="6"
                    ></textarea>
                </div>
                <div class="mt-4 mb-2 d-flex justify-content-between">
                    <div>
                        <button
                            [disabled]="!selectedSubjectId || !questionContent || answering || !selectedAIEngine"
                            class="btn btn-primary"
                            (click)="ask()"
                        >
                            <i class="bi-send"></i> Invia la richiesta
                        </button>
                    </div>
                </div>
                <div class="mt-4 form-group mb-4" [hidden]="!answering">
                    <div><label class="mb-2 fw-bold text-primary">Sto cercando la risposta...</label></div>
                </div>
                <div class="mt-4 form-group mb-4" *ngIf="answer && answer.content">
                    <div><label class="mb-2 fw-bold text-primary">Risposta</label></div>
                    <div>
                        <label class="mb-2 fw-bold">{{answer.content}}</label>
                    </div>
                </div>		
			</div>
        </div>
    </div>
</div>
