<div class="container pb-2 mt-4">
	<h2>
		<i class="bi bi-chat-right-text-fill"></i> Admin
	</h2>
    <div class="card mt-4">
		<div class="card-body">
            <div class="form-group mb-2">
                <div class="form-group mb-4 mt-4">
                    <label class="mb-2 fw-bold text-primary">Tenant</label>
                    <select class="form-control" [(ngModel)]="selectedTenantId" (change)="onTenantChange($event)">
                        <option *ngFor="let tenant of tenants" [value]="tenant.tenant_id">
                            {{ tenant.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="tenant">
                    <button class="btn btn-primary" (click)="goToTenantChat()">Go To Tenant Chat</button>
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="tenant">
                    <label class="mb-2 fw-bold text-primary">Soggetto</label>
                    <select class="form-control" [(ngModel)]="selectedSubjectId" (change)="onSubjectChange($event)">
                        <option *ngFor="let subject of subjects" [value]="subject.subject_id">
                            {{ subject.name }}
                        </option>
                    </select>                    
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="selectedSubjectId">                    
                    <button class="btn btn-primary" *ngIf="!seePrompt" (click)="seePrompt = !seePrompt">See Prompt</button>
                    <div class="form-group mb-4 mt-4">
                        <textarea
                            *ngIf="seePrompt"
                            [(ngModel)]="selectedSubjectPrompt"
                            class="form-control"
                            rows="6"
                        ></textarea>
                    </div>
                    <div class="form-group mb-4 mt-4">
                        <button class="btn btn-primary" *ngIf="seePrompt" (click)="submitPrompt()">Update Prompt</button>
                    </div>
                    <button class="btn btn-primary" (click)="goToSoggettoChat()">Go To Soggetto Chat</button>
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="selectedSubjectId">
                    <label class="mb-2 fw-bold text-primary">Documento</label>
                    <select class="form-control" [(ngModel)]="selectedDocumentId" (change)="onDocumentChange($event)">
                        <option *ngFor="let document of documents" [value]="document.document_id">
                            {{ document.name }}
                        </option>
                    </select>                   
                </div>                
                <div class="form-group mb-4 mt-4" *ngIf="selectedSubjectId">
                    <button class="btn btn-primary" (click)="adddoc = true" [hidden]="adddoc==true">Aggiungi documento</button>
                    <input [hidden]="adddoc==false" class="form-control" placeholder="Nome del documento" [(ngModel)]="newDocumentName"/>
                    <button [hidden]="adddoc==false" class="btn btn-primary" (click)="adddoc=false">Annulla</button> 
                    <button [hidden]="adddoc==false"class="btn btn-primary" (click)="addDocument()">Aggiungi Documento</button>
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="selectedDocument && selectedDocument.document_id">
                    <button class="btn btn-primary" (click)="removedoc = true" [hidden]="removedoc==true">Rimuovi il documento</button>
                    <span [hidden]="removedoc==false">
                        Sicuro di volere rimuovere il documento <b>{{selectedDocument.name}}</b>? 
                        <button class="btn btn-primary" (click)="removedoc=false">No</button> 
                        <button class="btn btn-primary" (click)="removeDocument()">Si</button>
                    </span>                    
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="selectedDocument && selectedDocument.body && !submitNew">
                    <h3>Document Content</h3>
                    <div>
                        <label class="mb-2 fw-bold text-primary">FILENAME:</label> {{selectedDocument.body.filename}}
                    </div>
                    <div>
                        <label class="mb-2 fw-bold text-primary">MIMETYPE:</label> {{selectedDocument.body.mimetype}}
                    </div>
                    <div class="form-group mb-4 mt-4" *ngFor="let digestedDocumentPage of selectedDocument.body.pages; let i = index">
                        <label class="mb-2 fw-bold text-primary">PAGE {{digestedDocumentPage.page}}</label>                        
                        <textarea
                            [(ngModel)]="digestedDocumentPage.text"
                            [readOnly]="true"
                            class="form-control"
                            rows="6"
                        ></textarea>
                    </div>
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="selectedSubjectId && selectedDocument">
                    <button class="btn btn-primary" (click)="submitNew = !submitNew">{{submitNew ? 'Return To See Document' : 'Update Document Content'}}</button>
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="submitNew">
                    <input class="form-control" type="file" #fileInput (change)="onFileChange($event)" accept="application/pdf">
                </div>
                <div class="form-group mb-4 mt-4" *ngIf="submitNew && digestedDocument">                    
                    <div>
                        <label class="mb-2 fw-bold text-primary">FILENAME:</label> {{digestedDocument.filename}}
                    </div>
                    <div>
                        <label class="mb-2 fw-bold text-primary">MIMETYPE:</label> {{digestedDocument.mimetype}}
                    </div>
                    <div class="form-group mb-4 mt-4" *ngFor="let digestedDocumentPage of digestedDocument?.pages; let i = index">
                        <label class="mb-2 fw-bold text-primary">PAGE {{digestedDocumentPage.page}}</label>                        
                        <textarea
                            [(ngModel)]="digestedDocumentPage.text"
                            class="form-control"
                            rows="6"
                        ></textarea>
                    </div>
                    <div>
                        <button class="btn btn-primary" (click)="submitDigestedDocument()">Submit</button><label *ngIf="loading">Loading...</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

