import { Component } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { User } from '../../model/User';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrl: './left-menu.component.scss'
})
export class LeftMenuComponent {

  public user:User = new User();

  constructor(private authService:AuthService){
    this.authService.currentUser.subscribe(x => (this.user = x));
  }



}
