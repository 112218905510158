import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Answer } from '../model/Answer';
import { environment } from '../environments/environment';
import { Tenant } from '../model/Tenant';
import { Subject } from '../model/Subject';
import { Document } from '../model/Document';
import { IngestedDocument } from '../model/IngestedDocument';
import { Question } from '../model/Question';

@Injectable({
  providedIn: 'root'
})

export class MainService {
    base_url = environment.BASE_WS_URL;

    constructor(private http: HttpClient) {}

    private handleError(error: HttpErrorResponse) {
      console.log(error);
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
      }
      // Return an observable with a user-facing error message.
      return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    getModelList(): Observable<any> {
      return this.http.get(this.base_url + '/engine/all');
    }

    getTenants(): Observable<Tenant[]> {
      return this.http.get<Tenant[]>(this.base_url + '/tenant/all');
    }

    getTenant(tenant_id:String): Observable<Tenant> {
      return this.http.get<Tenant>(this.base_url + '/tenant/' + tenant_id);
    }

    getSubjects(tenant_id:String): Observable<Subject[]> {
      return this.http.get<Subject[]>(this.base_url + '/subject/' + tenant_id);
    }

    getDocuments(tenant_id:String, subject_id:String): Observable<Document[]> {
      return this.http.get<Document[]>(this.base_url + '/document/' + tenant_id + "/" + subject_id);
    }

    digestDocument(file:File): Observable<IngestedDocument>{
      const formData = new FormData();
      formData.append('file', file);
      return this.http.post<IngestedDocument>(this.base_url + '/document/admin/ingest', formData);
    }

    submitDigestedDocument(tenant_id:String, subject_id:String, document_id:String, ingested:IngestedDocument): Observable<Document> {
      return this.http.put<Document>(this.base_url + '/document/admin/' + tenant_id + "/" + subject_id + '/' + document_id, ingested);
    }

    removeDocument(tenant_id:String, subject_id:String, document_id:String){
      return this.http.delete(this.base_url + '/document/admin/' + tenant_id + "/" + subject_id + '/' + document_id);
    }

    addDocument(tenant_id:String, subject_id:String, document_name:String){
      return this.http.post(this.base_url + '/document/admin/' + tenant_id + "/" + subject_id, {"document_name": document_name});
    }

    submitSubject(tenant_id:String, subject_id:String, subject:Subject): Observable<Subject> {
      return this.http.post<Subject>(this.base_url + '/subject/admin/' + tenant_id + "/" + subject_id, subject);
    }

    ask(tenant_id: String, subject_id: String, question: Question, ai_engine:string): Observable<Answer> {
      return this.http.post<Answer>(this.base_url + '/subject/' + tenant_id + "/" + subject_id + "/" + ai_engine + '/ask', question);
    }

    
}
